import { tokenKey } from '~/constants/auth'

const authRoutes = [
  'index',
  'login',
  'login-email',
  'reset',
  'reset-new',
  'signup',
  'signup-email',
  'sso-callback',
  'two-step-verification',
]

export default defineNuxtRouteMiddleware(to => {
  const token = useStatefulCookie(tokenKey)

  if (authRoutes.includes(to.name as string) && token.value) {
    return navigateTo({
      name: 'dashboard',
    })
  }
})
